import {inject, Injectable} from '@angular/core';
import {NotificationService} from '@progress/kendo-angular-notification';
import {TranslateService} from '@ngx-translate/core';

export type NotificationDataType = {
  message?: string,
  messageRaw?: string,
  type: 'none' | 'success' | 'warning' | 'error' | 'info',
}
@Injectable({providedIn: 'root'})
export class NotifyService {

  private _notificationService: NotificationService = inject(NotificationService);
  private _translateService: TranslateService = inject(TranslateService);


  public showNotification(data: NotificationDataType): void {
    let message = '';
    if (data.message) {
      message = this._translateService.instant(data.message);
    } else if (data.messageRaw) {
      message = data.messageRaw;
    }
    const ref = this._notificationService.show({
      content: message,
      animation: { type: "fade", duration: 800 },
      type: { style: data.type, icon: true },
      position: { horizontal: 'right', vertical: 'bottom' },
      closable: true,
      cssClass: ['k-white-space-pre-wrap', 'k-mb-sm', 'k-mr-md']
    });
    setTimeout(() => ref.hide(), 15000);
  }
}
